/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@fortawesome/fontawesome-pro/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&family=Roboto+Condensed:wght@300;400;700&display=swap');

.ng-select-material {
  @import '../node_modules/@ng-select/ng-select/scss/material.theme.scss';
}

.ng-select-default {
  @import '../node_modules/@ng-select/ng-select/scss/default.theme.scss';
}

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  p {
    @apply text-sm;
  }
  ol {
    @apply list-decimal ps-10;
  }
  ul {
    @apply list-disc ps-10;
  }
}

@layer components {
  .btn {
    @apply capitalize;
  }

  .btn-sm {
    @apply h-9;
  }

  .btn-white {
    @apply bg-white text-primary disabled:bg-opacity-40 disabled:text-opacity-40;
  }

  .input-transparent {
    @apply rounded-none border-transparent bg-transparent outline-none focus:border-gray-400 focus:border-b-black;
  }

  .modal-action {
    @apply border-t;
  }

  .modal-header {
    @apply border-b;
  }

  /* this is so nothing renders above the tooltip */
  .tooltip {
    @apply before:z-50 before:content-[attr(data-tip)];
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    @apply overflow-visible bg-[rgba(165,174,181,0.35)] bg-opacity-75 text-[#333];
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    @apply bg-[rgba(181,207,232,0.70)] text-[#333];
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked.ng-option-selected {
    @apply ring-1 ring-inset ring-primary ring-opacity-40;
  }

  .ng-select .ng-arrow {
    display: none !important;
  }

  .ng-select .ng-arrow-wrapper {
    @apply m-1 size-5 bg-center bg-no-repeat;
  }
}

.input-transparent {
  border-bottom: 1px gray solid;
}

.fa-stack.small {
  font-size: 0.7em;
  i {
    vertical-align: middle;
  }
}

.modal-box {
  padding: 0;
}

.modal-body {
  padding: 10px 15px;
}

.modal-header {
  position: sticky;
  background: white;
  top: 0;
  padding: 15px;
  z-index: 10;
}

.modal-action {
  position: sticky;
  background: white;
  bottom: 0;
  padding: 15px;
  z-index: 10;
}

body {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Condensed', sans-serif;
}

#ProfileFieldQuestion > p {
  font-weight: 500;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
}

.ng-dropdown-panel {
  margin-top: 5px;
}
// For ng dropdown when append to the body since the component wont be in the ng-select-default container
.ng-dropdown-panel .scroll-host {
  background-color: #ffffff;
  width: fit-content;
  min-width: 100%;
  border: 1px solid #ccc;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.5);
  padding: 3px 3px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px;
}

.ng-select .ng-arrow-wrapper {
  background-image: url('assets/icons/caret-down-solid.svg');
}

.ng-select-opened .ng-arrow-wrapper {
  background-image: url('assets/icons/caret-up-solid.svg');
}

// remove scroll and arrow from numeric input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/*
  DaisyUI v4 uses OKLCH for color definitions.
  Older browsers that do not support this CSS standard will have to fallback to the previous HSL definition.

  These variables are the fallback theme that will appear in older browsers.
  --fallback-p => primary
  --fallback-pc => primary-content
  by the same logic:
  --fallback-s -> success
  --fallback-a -> accent
  --fallback-n -> neutral
  --fallback-b1/b2/b3 -> base-100/200/300
  --fallback-in -> info
  --fallback-su -> success
  --fallback-er -> error
 */
@supports not (color: oklch(0 0 0)) {
  :root {
    color-scheme: light;
    --fallback-p: #224cc3;
    --fallback-pc: #fff;
    --fallback-s: #143388;
    --fallback-sc: #fff;
    --fallback-a: #a9025c;
    --fallback-ac: #fff;
    --fallback-n: #d0d2d3;
    --fallback-nc: #000;
    --fallback-b1: #ffffff;
    --fallback-b2: #e8e8e8;
    --fallback-b3: #d1d1d1;
    --fallback-bc: #000;
    --fallback-in: #90a5e1;
    --fallback-inc: #000;
    --fallback-su: #48a942;
    --fallback-suc: #000;
    --fallback-wa: #ffd36e;
    --fallback-wac: #000;
    --fallback-er: #a5193e;
    --fallback-erc: #fff;
  }
}
